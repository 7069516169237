export const GRAPHQL_API_URL = `${import.meta.env.VITE_API_URL}graphql`;
export const REST_API_URL = `${import.meta.env.VITE_API_URL}api/`;
export const API_ROOT_URL = import.meta.env.VITE_API_URL;

// Localstorage keys
export const LS_AUTHENTICATION_KEY = 'RM_AUTHENTICATION';
export const LS_IMPERSONATION_KEY = 'RM_IMPERSONATION';
export const LS_THEME_MODE_KEY = 'RM_THEME_MODE';
export const LS_ASSIGNMENT_GRID_KEY = 'RM_ASSIGNMENT_GRID';
export const LS_RETURN_URL_KEY = 'RM_RETURN_URL';
export const LS_BROWSER_PERMISSION_ALERT = 'LS_BROWSER_PERMISSION_ALERT';
export const LS_DEFAULT_CAMPAIGN_TYPE = 'LS_DEFAULT_CAMPAIGN_TYPE';

export const EMPLOYEE_TYPE_ID = 1;
export const SALES_CONSULTANT_TYPE_ID = 2;

export const ASSIGNMENT_STATE = Object.freeze({
  NOT_SET: 0,
  COMPLETED: 1,
  NOT_COMPLETED: 2
});

export const CAMPAIGN_TYPE = Object.freeze({
  ALL: 0,
  NORMAL: 5,
  SKB: 6,
  VACATION: 10,
  ILLNESS: 11,
  FLOWER: 13
});

export const ASSIGNMENT_STATUS = Object.freeze({
  ORDERED: 1,
  LOCKED_FOR_CUSTOMER_EDIT: 4,
  BOOKED: 16,
  NOT_ARRIVED: 32,
  ARRIVED: 64,
  REPORTED: 128,
  APPROVED: 1024,
  FINISHED: 4096
});

export const ASSIGNMENT_STATUS_NAME = Object.freeze({
  ORDERED: 'Bestilt',
  LOCKED_FOR_CUSTOMER_EDIT: 'Låst for kunde',
  BOOKED: 'Booket',
  NOT_ARRIVED: 'Ikke ankommet',
  ARRIVED: 'Ankommet',
  REPORTED: 'Indrapporteret',
  APPROVED: 'Godkendt',
  FINISHED: 'Afsluttet'
});

type TypeAssignmentColors = {
  [key: string]: {
    backgroundColor: string;
    textColor: string;
  };
};

export const ASSIGNMENT_STATUS_COLOR: TypeAssignmentColors = Object.freeze({
  ORDERED: {
    backgroundColor: '#f15a5a',
    textColor: '#fff'
  },
  LOCKED_FOR_CUSTOMER_EDIT: {
    backgroundColor: '#FFEB3B',
    textColor: '#000'
  },
  BOOKED: {
    backgroundColor: '#10b552',
    textColor: '#fff'
  },
  NOT_ARRIVED: {
    backgroundColor: '#fa983a',
    textColor: '#000'
  },
  ARRIVED: {
    backgroundColor: '#7ae45f',
    textColor: '#000'
  },
  REPORTED: {
    backgroundColor: '#81ecec',
    textColor: '#000'
  },
  APPROVED: {
    backgroundColor: '#2d95bf',
    textColor: '#fff'
  },
  FINISHED: {
    backgroundColor: '#945ba5',
    textColor: '#fff'
  }
});

export const QUESTION_TYPE = Object.freeze({
  TEXTFIELD: {
    id: 1,
    name: 'Tekstboks', // Tekstfelt (normalt)
    allowChildren: false
  },
  TEXTAREA: {
    id: 2,
    name: 'Stor tekstboks', // Tekstfelt (stort)
    allowChildren: false
  },
  DROPDOWN: {
    id: 3,
    name: 'Dropdown (vælg en)', // Dropdown (single choice)
    allowChildren: true
  },
  DROPDOWN_MULTIPLE: {
    id: 6,
    name: 'Afkrydsningsfelt (vælg flere)', // Dropdown (mutiple choice)
    allowChildren: true
  },
  RADIOGROUP: {
    id: 4,
    name: 'Select', // Radioknapper
    allowChildren: true
  }
});

export const MODES = Object.freeze({
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  CREATE_CHILD: 'CREATE_CHILD',
  UPDATE_CHILD: 'UPDATE_CHILD'
});

export const FILE_TYPE = Object.freeze({
  BEFORE: 2,
  AFTER: 1
});

export const USER_ROLE = Object.freeze({
  ADMINISTRATOR: 'Administrator',
  USER: 'User',
  CUSTOMER: 'Customer',
  SALES_CONSULANT: 'SalesConsulant',
  CAMPAIGN_ADMINISTRATOR: 'CampaignAdministrator',
  RETAILMANAGER_ADMINISTRATOR: 'RetailManagerAdministrator',
  EMPLOYEE: 'Employee',
  EMPLOYEE_ONGOING_CAMPAIGN: 'EmployeeOngoingCampaign',
  REGNSKAB: 'Regnskab',
  NATKUNDE: 'NatKunde',
  TEAMLEDER: 'Teamleder',
  TEAMLEDER_NAT: 'TeamlederNat',
  MODTAGER_SMILEY_RAPPORT: 'Modtager smileyrapport',
  API_USER: 'ApiUser'
});

export const SCHEDULER_DEFAULT_VIEW = 'Week';

export const SCHEDULER_LOAD_ASSIGNMENTS = Object.freeze({
  BY_CUSTOMER: 'BY_CUSTOMER',
  BY_EMPLOYEE: 'BY_EMPLOYEE',
  BY_SEARCH: 'BY_SEARCH'
});

export const THEME_MODE = Object.freeze({
  LIGHT: 'LIGHT',
  DARK: 'DARK'
});

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { captureMessage } from '@sentry/react';

export default function UpdatePrompt() {
  const handleReload = () => {
    captureMessage('New version ready. Reloading...', {
      level: 'info',
      tags: { git_commit: __GIT_COMMIT__ }
    });

    const url = window.location.href.toString().split('?')[0];
    window.location.href = url + '?reloaded';
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          fontSize: 18,
          paddingBottom: 0,
          marginBottom: 1.5,
          textAlign: {
            xs: 'center',
            sm: 'left'
          }
        }}
      >
        Ny version tilgængelig
      </DialogTitle>

      <DialogContent
        sx={{
          marginBottom: 1.5,
          textAlign: {
            xs: 'center',
            sm: 'left'
          }
        }}
      >
        <DialogContentText
          sx={{
            fontSize: 15
          }}
        >
          Der er en ny version af RetailManager tilgængelig.
        </DialogContentText>
      </DialogContent>

      <DialogActions
        sx={{
          backgroundColor: '#f9fafb',
          display: 'flex',
          justifyContent: {
            xs: 'center',
            sm: 'flex-end'
          }
        }}
      >
        <Button
          autoFocus
          color="primary"
          onClick={handleReload}
          variant="contained"
          sx={{
            fontSize: 15,
            width: {
              xs: '100%',
              sm: 'auto'
            }
          }}
        >
          Indlæs ny version
        </Button>
      </DialogActions>
    </Dialog>
  );
}

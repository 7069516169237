import { MenuItem } from '@mui/material';
import { AuthContext } from 'Contexts/AuthContext';
import { getCurrentUserData, hardRedirectToSignIn } from 'Helpers/AuthHelper';
import { exitImpersonation, isImpersonating } from 'Helpers/ImpersonateHelper';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AvatarDropdownMenu } from './DropdownMenu';

export default function UserDropdownMenu() {
  const { name, initials } = getCurrentUserData();
  const { isAdministrator } = useContext(AuthContext);

  return (
    <div title={name}>
      <AvatarDropdownMenu text={initials}>
        {/* TODO: Find new feedback tool */}
        {/* {isAdministrator && (
          <MenuItem onClick={() => {}}>Send feedback</MenuItem>
        )} */}

        {isImpersonating() && (
          <MenuItem onClick={() => exitImpersonation()}>
            Tilbage til egen profil
          </MenuItem>
        )}

        <MenuItem
          component={Link}
          to="/account/change-password"
          style={{ width: 200 }}
        >
          Skift kodeord
        </MenuItem>
        <MenuItem
          onClick={() => {
            hardRedirectToSignIn();
          }}
          style={{ width: 200 }}
        >
          Log ud
        </MenuItem>
      </AvatarDropdownMenu>
    </div>
  );
}

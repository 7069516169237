import React, { lazy, Suspense } from 'react';
import { USER_ROLE, REST_API_URL } from 'Constants';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

// DASHBOARD
const DashboardAssignment = lazy(() => import('Components/Dashboard/Assignments'));
const DashboardAssignmentScheduler = lazy(() => import('Components/Dashboard/Assignments/Scheduler/Index'));
const DashboardAssignmentCreate = lazy(() => import('Components/Dashboard/Assignments/Assignment-Create--Employee'));
const DashboardAssignmentReport = lazy(() => import('Components/Dashboard/Assignments/Report'));
const DashboardAssignmentShow = lazy(() => import('Components/Dashboard/Assignments/Show'));
const DashboardMyAssignments = lazy(() => import('Components/Dashboard/Assignments/MyAssignments'));
const DashboardCompletedAssignments = lazy(() => import('Components/Dashboard/Assignments/Completed'));
const DashboardAssignmentSkbReport = lazy(() => import('Components/Dashboard/Assignments/SkbReport'));
const DashboardEmployeeUpdateCustomerCard = lazy(() => import('Components/Dashboard/CustomerCard/Update'));

// --> Customer
const Dashboard__AsCustomer = lazy(() => import('Components/Dashboard/Dashboard__AsCustomer/Dashboard--AsCustomer'));
const AssignmentShow__AsCustomer = lazy(() => import('Components/Assignments/Assignment-Show--AsCustomer'));

// --> SKB Customres
const Dashboard__AsSkbCustomer = lazy(() => import('Components/Dashboard/Dashboard--AsSkbCustomer'));
// --> SALES CONSULTANTS
const SalesConsultant__Dashboard = lazy(() => import('Components/Dashboard/Dashboard--AsSalesConsultant'));
const SalesConsultant__CreateAssignment = lazy(() => import('Components/Assignments/Assignment-Create--SalesConsultant'));
const SalesConsultant__EditAssignment = lazy(() => import('Components/Assignments/Assignment-Edit--SalesConsultant'));
const SalesConsultant__MyAssignments = lazy(() => import('Components/Assignments/MyAssignments--SalesConsultant'));
const SalesConsultant__AssignmentShow = lazy(() => import('Components/Assignments/Assignment-Show--SalesConsultant'));

// ASSIGNMENTS
const AssignmentList = lazy(() => import('Components/Assignments/Assignment-List'));

const AssignmentCreate = lazy(() => import('Components/Assignments/Assignment-Create'));
const AssignmentEdit = lazy(() => import('Components/Assignments/Assignment-Edit'));
const AssignmentChangeEmployee = lazy(() => import('Components/Assignments/Assignment-ChangeEmployee'));
const AssignmentShowImages = lazy(() => import('Components/Assignments/Assignment-ShowImages'));
const AssignmentHistory = lazy(() => import('Components/Assignments/Assignment-History'));
const AssignmentInternalNote = lazy(() => import('Components/Assignments/Assignment-InternalNote'));
const AssignmentCustomerNote = lazy(() => import('Components/Assignments/Assignment-CustomerNote'));
const AssignmentCustomerCard = lazy(() => import('Components/Assignments/Assignment-CustomerCard'));

// CAMPAIGNS
const CampaignList = lazy(() => import('Components/Campaigns/Campaign-List'));
const CampaignEdit = lazy(() => import('Components/Campaigns/Campaign-Edit'));
const CampaignCreate = lazy(() => import('Components/Campaigns/Campaign-Create'));
const CampaignReportAsCustomer = lazy(() => import('Components/Campaigns/Campaign-Report--AsCustomer'));

// CUSTOMERS
const CustomerList = lazy(() => import('Components/Customers/Customer-List'));
const CustomerScheduler = lazy(() => import('Components/Customers/CustomerScheduler/Customer__Scheduler'));
const Customer__CreateAssignment = lazy(() => import('Components/Assignments/Assignment-Create--Customer'));


const Scheduler__AsSkbCustomer = lazy(() => import('Components/Customers/Scheduler--AsSkbCustomer'));
const CostReport__AsSkbCustomer = lazy(() => import('Components/Customers/CostReport--AsSkbCustomer'));

const SkbCustomerAssignmentCreate = lazy(() => import('Components/Customers/SkbCustomer-Assignment-Create'));
const SkbCustomerAssignmentEdit = lazy(() => import('Components/Customers/SkbCustomer-Assignment-Edit'));

const CustomerCreate = lazy(() => import('Components/Customers/Customer/Customer-Create'));
const CustomerEdit = lazy(() => import('Components/Customers/Customer/Customer-Edit'));

// SKB CUSTOMERS
const SkbCustomerList = lazy(() => import('Components/Customers/SkbCustomer-List'));

// CAMPAIGN PLANS
const CampaignPlanListAsCustomer = lazy(() => import('Components/CampaignPlans/CampaignPlan-List--AsCustomer'));
const CampaignPlanCreate = lazy(() => import('Components/CampaignPlans/CampaignPlan-Create'));
const CampaignPlanCreateAsCustomer = lazy(() => import('Components/CampaignPlans/CampaignPlan-Create--AsCustomer'));

const CampaignPlanEdit = lazy(() => import('Components/CampaignPlans/CampaignPlan-Edit'));

// CUSTOMER CARDS
const CustomerCardListAsCustomer = lazy(() => import('Components/CustomerCards/CustomerCard-List--AsCustomer'));
const CustomerCardListAsSalesConsultant = lazy(() => import('Components/CustomerCards/CustomerCard-List--AsSalesConsultant'));

// CHAINS
const ChainList = lazy(() => import('Components/Chains/Chain-List'));
const ChainCreate = lazy(() => import('Components/Chains/Chain-Create'));
const ChainEdit = lazy(() => import('Components/Chains/Chain-Edit'));

// STORES
const StoreList = lazy(() => import('Components/Stores/Store-List'));
//const Stores = lazy(() => import('Components/Stores'));
const StoreCreate = lazy(() => import('Components/Stores/Store-Create'));
const StoreEdit = lazy(() => import('Components/Stores/Store-Edit'));
const StorePrintLabels = lazy(() => import('Components/Stores/Store-PrintLabels'));

// EMPLOYEES
const EmployeeList = lazy(() => import('Components/Employees/Employee-List'));
const EmployeeCreate = lazy(() => import('Components/Employees/Employee-Create'));
const EmployeeEdit = lazy(() => import('Components/Employees/Employee-Edit'));
const EmployeeInternalNote = lazy(() => import('Components/Employees/Employee-InternalNote'));
const EmployeeScheduler = lazy(() => import('Components/Employees/Employee-Scheduler'));

// SALES CONSULTANTS
const SalesConsultants = lazy(() => import('Components/SalesConsultants/SalesConsultant-List'));
const SalesConsultantCreate = lazy(() => import('Components/SalesConsultants/SalesConsultant-Create'));
const SalesConsultantEdit = lazy(() => import('Components/SalesConsultants/SalesConsultant-Edit'));

// SMILEY SURVEYS
const SmileySurveyList = lazy(() => import('Components/SmileySurveys/SmileySurvey-List'));
const SmileySurveyParticipants = lazy(() => import('Components/SmileySurveys/SmileySurvey-Participants'));
const SmileySurveyEdit = lazy(() => import('Components/SmileySurveys/SmileySurvey-Edit'));

// EMAILS
const Emails = lazy(() => import('Components/Emails/Email-List'));
const EmailEdit = lazy(() => import('Components/Emails/Email-Edit'));

// NEWS
const News = lazy(() => import('Components/News/News-List'));
const NewsCreate = lazy(() => import('Components/News/News-Create'));
const NewsEdit = lazy(() => import('Components/News/News-Edit'));

// SALARIES
const SalaryList = lazy(() => import('Components/Salaries/Salary-List'));

// APPROVE ASSIGNMENTS
const ApproveAssignments = lazy(() => import('Components/Approval/Approve-Assignments'));

// INVOICING
const InvoiceReadyList = lazy(() => import('Components/Invoicing/Invoice-Ready-List'));

// INVOICING
const ImportAssignments = lazy(() => import('Components/Assignments/Assignment-Import'));

// TODOLISTS
const TodoListsList = lazy(() => import('Components/TodoLists/TodoList-List'));
const TodoListItemsListBySearch = lazy(() => import('Components/TodoLists/Items/TodoList-Items-List--BySearch'));
const TodoListItemsListByTodoList = lazy(() => import('Components/TodoLists/Items/TodoList-Items-List--ByTodoList'));
const TodoListItemsEdit = lazy(() => import('Components/TodoLists/Items/TodoList-Items-Edit'));
const TodoListItemsFollowUp = lazy(() => import('Components/TodoLists/Items/TodoList-Items-FollowUp'));
const TodoListList__AsSkbCustomer = lazy(() => import('Components/TodoLists/TodoList-List--AsSkbCustomer'));
const TodoListItemsList__AsSkbCustomer = lazy(() => import('Components/TodoLists/Items/TodoList-Items-List--AsSkbCustomer'));
const TodoListItemsPrint__AsSkbCustomer = lazy(() => import('Components/TodoLists/Items/TodoList-Items-Print--AsSkbCustomer'));
const TodoListItemsEdit__AsSkbCustomer = lazy(() => import('Components/TodoLists/Items/TodoList-Items-Edit--AsSkbCustomer'));

// MISC
const Help = lazy(() => import('Components/Help/Help'));
const Whistleblower = lazy(() => import('Components/Whistleblower/Whistleblower'));
const AccountChangeOwnPassword = lazy(() => import('Components/Account/Account-ChangeOwnPassword'));
const AccountResetPasswordForOther = lazy(() => import('Components/Account/Account-ResetPasswordForOther'));

const TeamleaderEmployees = lazy(() => import('Components/Teamleader/Teamleader__Employees'));
const TeamleaderEmployeeSearch = lazy(() => import('Components/Teamleader/Teamleader__EmployeeSearch'));

export type Route = Omit<RouteObject, 'children'> & {
  label?: string;
  path: string;
  type?: 'link' | 'route' | 'download' | 'group';
  allowed?: string[];
  denied?: string[];
  children?: Route[];
};

const routeItems: Route[] = [

  // Home / Dashboard
  {
    label: 'Forside',
    path: '/',
    type: 'link',
    allowed: [
      USER_ROLE.ADMINISTRATOR,
      USER_ROLE.CAMPAIGN_ADMINISTRATOR,
      USER_ROLE.EMPLOYEE,
      USER_ROLE.RETAILMANAGER_ADMINISTRATOR,
      USER_ROLE.TEAMLEDER_NAT
    ],
    element: <DashboardAssignment />
  },

  // CUSTOMER: Home / Dashboard
  {
    label: 'Forside',
    path: '/',
    type: 'link',
    allowed: [USER_ROLE.CUSTOMER],
    denied: [USER_ROLE.NATKUNDE],
    element: (
      <>
        <Dashboard__AsCustomer />

        <Suspense>
          <Outlet />
        </Suspense>
      </>
    ),
    children: [

      // EDIT ASSIGNMENT
      {
        path: '/assignments/:assignmentId',
        element: <AssignmentShow__AsCustomer />
      },

      // ASSIGNMENT CUSTOMER NOTE
      {
        path: '/assignments/:assignmentId/customer-note',
        element: <AssignmentCustomerNote />
      },

      // ASSIGNMENT SHOW IMAGE
      {
        path: '/assignments/:assignmentId/images',
        element: <AssignmentShowImages />
      },
    ]
  },

  // SKB CUSTOMER: Home / Dashboard
  {
    label: 'Forside',
    path: '/',
    type: 'link',
    allowed: [USER_ROLE.NATKUNDE],
    element: <Dashboard__AsSkbCustomer />
  },

  // SALES CONSULTANT: Home / Dashboard
  {
    label: 'Forside',
    path: '/',
    type: 'link',
    allowed: [USER_ROLE.SALES_CONSULANT],
    element: (
      <>
        <SalesConsultant__Dashboard />

        <Suspense>
          <Outlet />
        </Suspense>
      </>
    ),
    children: [

      // Assignment show
      {
        label: 'Opgave',
        path: '/salesconsultant/assignments/:assignmentId',
        element: <SalesConsultant__AssignmentShow />
      },

      // Assignment edit
      {
        label: 'Ret opgave',
        path: '/salesconsultant/assignments/edit/:assignmentId',
        element: <SalesConsultant__EditAssignment />
      },

      // Assignment images
      {
        path: '/salesconsultant/assignments/show-images/:assignmentId',
        element: <AssignmentShowImages />
      },
    ]
  },

  // CUSTOMER CREATE ASSIGNMENT
  {
    label: 'Opret opgave',
    path: '/assignments/create',
    type: 'link',
    allowed: [USER_ROLE.CUSTOMER],
    element: <Customer__CreateAssignment />
  },

  // CUSTOMERS: Scheduler
  {
    label: 'Arbejdsplan',
    path: '/customers/scheduler/:customerId',
    allowed: [USER_ROLE.TEAMLEDER_NAT],
    element: <CustomerScheduler />
  },

  // Approve Assignments
  {
    label: 'Afslut opgaver',
    path: '/approve-assignments',
    allowed: [USER_ROLE.ADMINISTRATOR],
    element: <ApproveAssignments />
  },


  // {
  //   // Create SKB assignment
  //   label: 'Ny SKB opgave',
  //   path: '/assignments/skb/create/:customerId/:startDateTime',
  //   allowed: [USER_ROLE.ADMINISTRATOR, USER_ROLE.TEAMLEDER_NAT],
  //   element: (
  //     <Suspense>
  //       <CustomerScheduler />
  //       <SkbCustomerAssignmentCreate />
  //     </Suspense>
  //   )
  // },

  // {
  //   // Update SKB assignment
  //   label: 'Ret SKB opgave',
  //   path: '/assignments/skb/:customerId/:assignmentId',
  //   allowed: [USER_ROLE.ADMINISTRATOR, USER_ROLE.TEAMLEDER_NAT],
  //   element: (
  //     <Suspense>
  //       <CustomerScheduler />
  //       <SkbCustomerAssignmentEdit />
  //     </Suspense>
  //   )
  // },



  // DASHBOARD: Scheduler
  {
    label: 'Arbejdsplan',
    path: '/dashboard/assignments/scheduler',
    type: 'link',
    allowed: [
      USER_ROLE.CAMPAIGN_ADMINISTRATOR,
      USER_ROLE.EMPLOYEE,
      USER_ROLE.RETAILMANAGER_ADMINISTRATOR,
      USER_ROLE.TEAMLEDER_NAT
    ],
    denied: [USER_ROLE.ADMINISTRATOR],
    element: <DashboardAssignmentScheduler />
  },

  // DASHBOARD: My assignment
  {
    label: 'Mine opgaver',
    path: '/dashboard/my-assignment',
    type: 'link',
    allowed: [USER_ROLE.EMPLOYEE],
    denied: [USER_ROLE.ADMINISTRATOR],
    element: (
      <>
        <DashboardMyAssignments />

        <Suspense>
          <Outlet />
        </Suspense>
      </>
    ),
    children: [

      // ASSIGNMENTs: SHOW IMAGES
      {
        label: 'Opgave billeder',
        path: '/dashboard/my-assignment/show-images/:assignmentId',
        element: <AssignmentShowImages />
      }
    ]
  },

  // DASHBOARD: Completed assignments
  {
    label: 'Indrapporterede opgaver',
    path: '/dashboard/completed-assignment',
    type: 'link',
    allowed: [USER_ROLE.EMPLOYEE],
    denied: [USER_ROLE.ADMINISTRATOR],
    element: <DashboardCompletedAssignments />
  },

  // DASHBOARD: Create assignment
  {
    label: 'Opret opgave',
    path: '/dashboard/assignments/create',
    type: 'link',
    allowed: [USER_ROLE.EMPLOYEE, USER_ROLE.EMPLOYEE_ONGOING_CAMPAIGN],
    denied: [USER_ROLE.SALES_CONSULANT, USER_ROLE.ADMINISTRATOR],
    element: <DashboardAssignmentCreate />
  },


  // DASHBOARD: Show assignment
  {
    path: '/dashboard/assignments/:assignmentId',
    allowed: [USER_ROLE.EMPLOYEE],
    element: <DashboardAssignmentShow />
  },

  // DASHBOARD: SKB report -> Todolist
  {
    path: '/dashboard/assignments/skb-report/:todoListId',
    allowed: [USER_ROLE.EMPLOYEE, USER_ROLE.TEAMLEDER_NAT],
    element: <DashboardAssignmentSkbReport showHomeButton={true} />
  },

  // DASHBOARD: Report -> Report
  {
    path: '/dashboard/assignments/report/:assignmentId',
    allowed: [USER_ROLE.EMPLOYEE],
    element: <DashboardAssignmentReport />
  },

  // DASHBOARD: Report -> Images
  {
    path: '/dashboard/assignments/report/images/:assignmentId',
    allowed: [USER_ROLE.EMPLOYEE],
    element: <DashboardAssignmentReport goDirectlyToImageStep={true} />
  },

  // DASHBOARD: Customer card -> Edit
  {
    path: '/employee/customercard/:customerCardId',
    allowed: [USER_ROLE.EMPLOYEE],
    element: <DashboardEmployeeUpdateCustomerCard />
  },


  // SALESCONSULTANT: Create assignment
  {
    label: 'Opret opgave',
    path: '/dashboard/assignments/create',
    type: 'link',
    allowed: [USER_ROLE.SALES_CONSULANT],
    element: <SalesConsultant__CreateAssignment />
  },


      
  // **** CUSTOMER ****

  // CAMPAIGN REPORTS
  {
    label: 'Kampagne oversigt',
    path: '/campaigns/report',
    type: 'link',
    allowed: [USER_ROLE.CUSTOMER],
    element: <CampaignReportAsCustomer />
  },
  {
    path: '/campaigns/report/:campaignId',
    allowed: [USER_ROLE.CUSTOMER],
    element: <CampaignReportAsCustomer />
  },


  {
    label: 'Kundekort oversigt',
    path: '/customercards',
    type: 'link',
    allowed: [USER_ROLE.CUSTOMER],
    denied: [USER_ROLE.NATKUNDE],
    element: <CustomerCardListAsCustomer />
  },


  // CAMPAIGN PLANS
  {
    label: 'Kampagneplan',
    path: '/campaignplans',
    type: 'link',
    allowed: [USER_ROLE.CUSTOMER],
    element: (
      <>
        <CampaignPlanListAsCustomer />

        <Suspense>
          <Outlet />
        </Suspense>
      </>
    ),
    children: [
      {
        label: 'Opret kampagneplan',
        path: '/campaignplans/campaignplans/create',
        element: <CampaignPlanCreateAsCustomer />
      },

      {
        label: 'Ret kampagneplan',
        path: '/campaignplans/campaignplans/:campaignPlanId',
        element: <CampaignPlanEdit />
      }
    ]
  },



  // **** NATKUNDE ****
  {
    type: 'group',
    label: 'Todolister',
    path: '',
    allowed: [USER_ROLE.NATKUNDE],
    children: [

      // TODOLISTS
      {
        label: 'Oversigt',
        path: '/skbcustomers/todolists',
        type: 'link',
        element: (
          <>
            <TodoListList__AsSkbCustomer />

            <Suspense>
              <Outlet />
            </Suspense>
          </>
        ),
        children: [

          // TODOLIST SHOW
          {
            path: '/skbcustomers/todolists/:todoListId',
            element: <TodoListItemsListByTodoList />
          },

          // TODOLIST EDIT
          {
            path: '/skbcustomers/todolists/:todoListId/edit',
            element: <TodoListItemsEdit />
          },

          // TODOLIST FOLLOW UP
          {
            path: '/skbcustomers/todolists/:todoListId/followup',
            element: <TodoListItemsFollowUp />
          }
        ]
      },

      // TODOLIST ITEMS
      {
        label: 'Opgaver',
        path: '/skbcustomers/todolists/items',
        type: 'link',
        element: (
          <>
            <TodoListItemsList__AsSkbCustomer />
            <Suspense><Outlet /></Suspense>
          </>
        ),
        children: [

          // TODOLIST ITEM EDIT
          {
            path: '/skbcustomers/todolists/items/:campaignId/:todoListId',
            element: <TodoListItemsEdit__AsSkbCustomer />
          },
        ]
      },


      // TODOLIST ITEMS PRINT
      {
        label: 'Udskriv todoliste',
        path: '/skbcustomers/todolists/items/print/:todoListId',
        element: <TodoListItemsPrint__AsSkbCustomer />
      }
    ]
  },

  // SCHEDULER
  {
    label: 'Arbejdsplan',
    path: '/skbcustomers/scheduler',
    type: 'link',
    allowed: [USER_ROLE.NATKUNDE],
    element: <Scheduler__AsSkbCustomer />
  },
  
  // SKB Customers -> Scheduler (no customer selected) -> Redirect
  {
    denied: [USER_ROLE.NATKUNDE],
    path: '/skbcustomers/scheduler',
    element: <Navigate to="/skbcustomers" />
  },



  // COST REPORT
  {
    label: 'Udtræk',
    path: '/customers/cost-report',
    type: 'link',
    allowed: [USER_ROLE.NATKUNDE],
    element: <CostReport__AsSkbCustomer />
  },

  // DASHBOARD: Help
  {
    label: 'Hjælp',
    path: '/help',
    type: 'link',
    allowed: [USER_ROLE.EMPLOYEE],
    denied: [USER_ROLE.ADMINISTRATOR],
    element: <Help />
  },

  // DASHBOARD: Reset password for other users
  {
    path: '/account/reset-password/',
    allowed: [USER_ROLE.ADMINISTRATOR],
    element: <AccountResetPasswordForOther />
  },

  // DASHBOARD: Reset password for other users
  {
    path: '/account/change-password/',
    allowed: [],
    element: <AccountChangeOwnPassword />
  },


  



  // SALES CONSULTANT: MY ASSIGNMENTS
  {
    label: 'Mine opgaver',
    path: '/salesconsultant/my-assignments',
    type: 'link',
    allowed: [USER_ROLE.SALES_CONSULANT],
    element: (
      <>
        <SalesConsultant__MyAssignments />
        
        <Suspense>
          <Outlet />
        </Suspense>
      </>
    ),
    children: [

      // Assignment show
      {
        label: 'Opgave',
        path: '/salesconsultant/my-assignments/:assignmentId',
        element: <SalesConsultant__AssignmentShow />
      },

      // Assignment edit
      {
        label: 'Ret opgave',
        path: '/salesconsultant/my-assignments/edit/:assignmentId',
        element: <SalesConsultant__EditAssignment />
      },

      // Assignment images
      {
        path: '/salesconsultant/my-assignments/show-images/:assignmentId',
        element: <AssignmentShowImages />
      },
    ]
  },



  // SALES CONSULTANT: CUSTOMER CARDS
  {
    label: 'Kundekort oversigt',
    path: '/customercards',
    type: 'link',
    allowed: [
      USER_ROLE.CAMPAIGN_ADMINISTRATOR,
      USER_ROLE.SALES_CONSULANT
    ],
    denied: [USER_ROLE.NATKUNDE, USER_ROLE.ADMINISTRATOR],
    element: <CustomerCardListAsSalesConsultant />
  },


  {
    type: 'group',
    label: 'Teamleder',
    path: '',
    allowed: [USER_ROLE.TEAMLEDER, USER_ROLE.TEAMLEDER_NAT],
    children: [
      // "TeamLeaderIndex", "Employee" || n/a
      {
        label: 'Medarbejdere',
        path: '/teamleader/employees',
        type: 'link',
        element: (
          <>
            <TeamleaderEmployees />
            
            <Suspense>
              <Outlet />
            </Suspense>
          </>
        ),
        children: [

          // Employees: Scheduler
          {
            path: '/teamleader/employees/:employeeId/scheduler',
            element: <EmployeeScheduler enableEmployeeChanger={true} />
          },

          // Employees: Internal note
          {
            path: '/teamleader/employees/:employeeId/internal-note',
            element: <EmployeeInternalNote />
          }
        ]
      },

      // "TeamLeaderSearch", "Employee" || [Authorize(Roles = RoleConstants.TeamlederNat+","+RoleConstants.Teamleder)]
      {
        label: 'Find ledige medarbejdere',
        path: '/teamleader/employee-search',
        type: 'link',
        element: (
          <>
            <TeamleaderEmployeeSearch />

            <Suspense>
              <Outlet />
            </Suspense>
          </>
        ),
        children: [

          // Employees: Scheduler
          {
            path: '/teamleader/employee-search/:employeeId/scheduler',
            element: <EmployeeScheduler enableEmployeeChanger={true} />
          },

          // Employees: Internal note
          {
            path: '/teamleader/employee-search/:employeeId/internal-note',
            element: <EmployeeInternalNote />
          }
        ]
      }
    ]
  },

  // SKB Customers: List
  {
    label: 'SKB kunder',
    path: '/skbcustomers',
    type: 'link',
    allowed: [USER_ROLE.RETAILMANAGER_ADMINISTRATOR, USER_ROLE.TEAMLEDER_NAT],
    element: (
      <>
        <SkbCustomerList />
        <Suspense><Outlet /></Suspense>
      </>
    ),
    children: [

      // SKB Customers: Create
      {
        path: '/skbcustomers/create',
        element: <CustomerCreate />
      },


      // SKB Customers: Edit
      {
        path: '/skbcustomers/:customerId',
        element: (
          <Suspense>
            <CustomerEdit />
            <Outlet context={{ level: 2 }} />
          </Suspense>
        ),
        children: [

          // SKB Customers -> Create campaign plan
          {
            path: '/skbcustomers/:customerId/campaignplans/create',
            element: <CampaignPlanCreate />
          },

          // SKB Customers -> Edit campaign plan
          {
            path: '/skbcustomers/:customerId/campaignplans/:campaignPlanId',
            element: <CampaignPlanEdit />
          },

          // SKB Customers: Edit sales consultant
          {
            path: '/skbcustomers/:customerId/salesconsultants/:salesConsultantId',
            element: <SalesConsultantEdit />
          },

          // SKB Customers: Create sales consultant
          {
            path: '/skbcustomers/:customerId/salesconsultants/create',
            element: <SalesConsultantCreate />
          },

          // SKB Customers: Edit campaign
          {
            path: '/skbcustomers/:customerId/campaigns/:campaignId',
            element: <CampaignEdit />
          },

          // SKB Customers: Create campaign
          {
            path: '/skbcustomers/:customerId/campaigns/create',
            element: <CampaignCreate />
          }
        ]
      },
    ]
  },

  // SKB Customers -> Scheduler
  {
    path: '/skbcustomers/scheduler/:customerId',
    allowed: [
      USER_ROLE.ADMINISTRATOR,
      USER_ROLE.RETAILMANAGER_ADMINISTRATOR,
      USER_ROLE.TEAMLEDER_NAT
    ],
    element: (
      <>
        <CustomerScheduler />

        <Suspense>
          <Outlet />
        </Suspense>
      </>
    ),
    children: [
      
      // SKB Customers -> Scheduler -> Create assignment
      {
        path: '/skbcustomers/scheduler/:customerId/create/:startDateTime',
        element: <SkbCustomerAssignmentCreate />
      },

      // SKB Customers -> Scheduler -> Edit assignment
      {
        path: '/skbcustomers/scheduler/:customerId/:assignmentId',
        element: <SkbCustomerAssignmentEdit />
      },
    ]
  },


  //***** ROLE: ADMINISTRATOR *****
  // Administration dropdown
  {
    type: 'group',
    label: 'Administration',
    path: '',
    allowed: [USER_ROLE.ADMINISTRATOR],
    children: [

      // Employees: List
      {
        label: 'Medarbejdere',
        path: '/employees',
        type: 'link',
        element: (
          <>
            <EmployeeList />

            <Suspense>
              <Outlet />
            </Suspense>
          </>
        ),
        children: [

          // Employees: Create
          {
            label: 'Opret medarbejder',
            path: '/employees/create',
            element: <EmployeeCreate />
          },

          // Employees: Edit
          {
            label: 'Ret medarbejder',
            path: '/employees/:employeeId/:startTab',
            element: <EmployeeEdit />
          },

          // Employees: Scheduler
          {
            label: 'Planlægning',
            path: '/employees/scheduler/:employeeId',
            element: <EmployeeScheduler enableShowOtherAtWorkSwitch={true} />
          },

          // Employees: Internal note
          {
            label: 'Intern note',
            path: '/employees/internal-note/:employeeId',
            element: <EmployeeInternalNote />
          },
        ]
      },

      // SalesConsultants: List
      {
        label: 'Salgskonsulenter',
        path: '/salesconsultants',
        type: 'link',
        element: (
          <>
            <SalesConsultants />
            
            <Suspense>
              <Outlet />
            </Suspense>
          </>
        ),
        children: [

          // SalesConsultants: Create
          {
            label: 'Opret salgskonsulent',
            path: '/salesconsultants/create/:customerId',
            element: <SalesConsultantCreate />
          },

          // SalesConsultants: Edit
          {
            label: 'Ret salgskonsulent',
            path: '/salesconsultants/:salesConsultantId',
            element: <SalesConsultantEdit />
          }
        ]
      },

      // Emails: List
      {
        label: 'Emails',
        path: '/emails',
        type: 'link',
        element: (
          <>
            <Emails />

            <Suspense>
              <Outlet />
            </Suspense>
          </>
        ),
        children: [

          // Emails: Edit
          {
            label: 'Ret email',
            path: '/emails/:emailId',
            element: <EmailEdit />
          }
        ]
      },

      // News: List
      {
        label: 'Nyheder',
        path: '/news',
        type: 'link',
        element: (
          <>
            <News />
            
            <Suspense>
              <Outlet />
            </Suspense>
          </>
        ),
        children: [

          // News: Create
          {
            label: 'Opret nyhed',
            path: '/news/create',
            element: <NewsCreate />
          },

          // News: Edit
          {
            label: 'Ret nyhed',
            path: '/news/:newsId',
            element: <NewsEdit />
          }
        ]
      },

      // Salary: list
      {
        label: 'Løn',
        path: '/salaries',
        type: 'link',
        element: <SalaryList />
      },

      // Invoices: List
      {
        label: 'Fakturering',
        path: '/invoicing',
        type: 'link',
        element: <InvoiceReadyList />
      },

      // Important assignments
      {
        label: 'Importer opgaver',
        path: '/import-assignments',
        type: 'link',
        allowed: [],
        element: <ImportAssignments />
      },

      // Smiley Surveys: List
      {
        label: 'Undersøgelser',
        path: '/smiley-surveys',
        type: 'link',
        element: (
          <>
            <SmileySurveyList />

            <Suspense>
              <Outlet />
            </Suspense>
          </>
        ),
        children: [
          
          // Smiley Surveys: Edit
          {
            path: '/smiley-surveys/:surveyId',
            element: <SmileySurveyEdit />
          }
        ]
      },

      // Smiley Surveys: Participants
      {
        label: 'Undersøgelses deltagere',
        path: '/smiley-surveys/participants/:surveyId',
        element: <SmileySurveyParticipants />
      },

      // Employees: Export
      {
        label: 'Medarbejderudtræk',
        path: `${REST_API_URL}Report/EmployeesReport`,
        type: 'download',
      }
    ]
  },

  //***** ROLE: ADMINISTRATOR *****
  // Customers: List
  {
    label: 'Kunder',
    path: '/customers',
    type: 'link',
    allowed: [USER_ROLE.ADMINISTRATOR],
    element: (
      <>
        <CustomerList />

        <Suspense>
          <Outlet />
        </Suspense>
      </>
    ),
    children: [

      // Customers: Create
      {
        label: 'Opret kunde',
        path: '/customers/create',
        element: <CustomerCreate />
      },

      // Customers: Edit
      {
        label: 'Ret kunde',
        path: '/customers/:customerId',
        element: (
          <Suspense>
            <CustomerEdit />
            <Outlet context={{ level: 2 }} />
          </Suspense>
        ),
        children: [

          // Customers: Create campaign plan
          {
            label: 'Opret kampagneplan',
            path: '/customers/:customerId/campaignplans/create',
            element: <CampaignPlanCreate />
          },

          // Customers: Edit campaign plan
          {
            label: 'Ret kampagneplan',
            path: '/customers/:customerId/campaignplans/:campaignPlanId',
            element: <CampaignPlanEdit />
          },

          // Customers: Create sales consultant
          {
            label: 'Opret salgskonsulent',
            path: '/customers/:customerId/salesconsultants/create',
            element: <SalesConsultantCreate />
          },

          // Customers: Edit sales consultant
          {
            label: 'Ret salgskonsulent',
            path: '/customers/:customerId/salesconsultants/:salesConsultantId',
            element: <SalesConsultantEdit />
          },

          // Customers: Edit campaign
          {
            path: '/customers/:customerId/campaigns/:campaignId',
            element: <CampaignEdit />
          },

          // Customers: Create campaign
          {
            path: '/customers/:customerId/campaigns/create',
            element: <CampaignCreate />
          }
        ]
      }
    ]
  },

  //***** ROLE: ADMINISTRATOR *****
  // Campaigns: List
  {
    label: 'Kampagner',
    path: '/campaigns',
    type: 'link',
    allowed: [USER_ROLE.ADMINISTRATOR],
    element: (
      <>
        <CampaignList />
        
        <Suspense>
          <Outlet />
        </Suspense>
      </>
    ),
    children: [

      // Campaigns: Edit
      {
        label: 'Ret kampagne',
        path: '/campaigns/:campaignId',
        element: <CampaignEdit />
      }
    ]
  },

  // {
  //   label: 'OpgaverV3',
  //   path: '/assignmentsV3',
  //   type: 'link',
  //   allowed: [USER_ROLE.ADMINISTRATOR],
  //   element: (
  //     <Suspense>
  //       <AssignmentListV3 />
  //       <Suspense><Outlet /></Suspense>
  //     </Suspense>
  //   ),
  // },


  //***** ROLE: ADMINISTRATOR *****
  // Assignments: List
  {
    label: 'Opgaver',
    path: '/assignments',
    type: 'link',
    allowed: [USER_ROLE.ADMINISTRATOR],
    element: (
      <>
        <AssignmentList />

        <Suspense>
          <Outlet />
        </Suspense>
      </>
    ),
    children: [
      
      // Assignments: Show images
      {
        label: 'Opgave billeder',
        path: '/assignments/show-images/:assignmentId',
        element: <AssignmentShowImages />
      },

      // Assignments: Create
      {
        label: 'Ny opgave',
        path: '/assignments/create/:assignmentId/:customerId',
        element: <AssignmentCreate />
      },

      // Assignments: Edit
      {
        label: 'Ret opgave',
        path: '/assignments/:assignmentId',
        element: <AssignmentEdit />
      },

      // Assignments: History
      {
        label: 'Opgave historik',
        path: '/assignments/history/:assignmentId',
        element: <AssignmentHistory />
      },

      // Assignments: Update Internal Note
      {
        label: 'Opgave note',
        path: '/assignments/internal-note/:assignmentId',
        element: <AssignmentInternalNote />
      },

      // Assignments: Update Customer Card
      {
        label: 'Kundekort',
        path: '/assignments/customercard/:customerCardId',
        element: <AssignmentCustomerCard />
      },

      // Assignments: Change Employee
      {
        path: '/assignments/change-employee/:assignmentId',
        element: <AssignmentChangeEmployee />
      },

      // Assignments -> Employee
      {
        path: '/assignments/employees/:employeeId',
        element: <EmployeeEdit />
      },

      // Assignments -> Sales Consultant
      {
        path: '/assignments/salesconsultants/:salesConsultantId',
        element: <SalesConsultantEdit />
      },

      // Assignments -> Customer
      {
        path: '/assignments/customers/:customerId',
        element: (
          <Suspense>
            <CustomerEdit />
            <Outlet context={{ level: 2 }} />
          </Suspense>
        ),
        children: [
          // Assignments -> Customer -> Create Campaign
          {
            path: '/assignments/customers/:customerId/campaigns/create',
            element: <CampaignCreate />
          },
          // Assignments -> Customer -> Edit Campaign
          {
            path: '/assignments/customers/:customerId/campaigns/:campaignId',
            element: <CampaignEdit />
          },

          // Assignments -> Customer -> Create Sales consultant
          {
            path: '/assignments/customers/:customerId/salesconsultants/create',
            element: <SalesConsultantCreate />
          },
          // Assignments -> Customer -> Edit Sales consultant
          {
            path: '/assignments/customers/:customerId/salesconsultants/:salesConsultantId',
            element: <SalesConsultantEdit />
          },

          // Assignments -> Customer -> Create Campaign plan
          {
            path: '/assignments/customers/:customerId/campaignplans/create',
            element: <CampaignPlanCreate />
          },
          // Assignments -> Customer -> Edit Campaign plan
          {
            path: '/assignments/customers/:customerId/campaignplans/:campaignPlanId',
            element: <CampaignPlanEdit />
          }
        ]
      },

      // Assignments -> Campaign
      {
        path: '/assignments/campaigns/:campaignId',
        element: <CampaignEdit />
      },

      // Assignments -> Store
      {
        path: '/assignments/stores/:storeId',
        element: <StoreEdit />
      },
    ]
  },


  //***** ROLE: ADMINISTRATOR *****

  // Customers -> Scheduler
  {
    label: 'Arbejdsplan',
    path: '/customers/scheduler/:customerId',
    allowed: [USER_ROLE.ADMINISTRATOR],
    element: (
      <>
        <CustomerScheduler />

        <Suspense>
          <Outlet />
        </Suspense>
      </>
    ),

    children: [
      // Customers -> Scheduler -> Create assignment
      {
        path: '/customers/scheduler/:customerId/create/:assignmentId',
        element: <AssignmentCreate />
      },

      // Customers -> Scheduler -> Edit assignment
      {
        path: '/customers/scheduler/:customerId/:assignmentId',
        element: <AssignmentEdit />
      }
    ]
  },
  // Customers -> Scheduler (no customer selected) -> Redirect
  {
    path: '/customers/scheduler/',
    element: <Navigate to="/customers" />
  },


  // Chains dropdown
  {
    type: 'group',
    path: '',
    label: 'Kæder',
    allowed: [USER_ROLE.ADMINISTRATOR],
    children: [
      // Stores: List
      {
        label: 'Butiksoversigt',
        path: '/stores',
        type: 'link',
        element: (
          <>
            <StoreList />

            <Suspense>
              <Outlet />
            </Suspense>
          </>
        ),
        children: [
          // Stores: Create
          {
            label: 'Opret butik',
            path: '/stores/create/:chainId',
            element: <StoreCreate />
          },

          // Stores: Edit
          {
            label: 'Ret butik',
            path: '/stores/:storeId',
            element: <StoreEdit />
          }
        ]
      },

      // Stores: Print Labels
      {
        path: '/stores/print-labels',
        element: <StorePrintLabels />
      },

      // Stores: Print Store Labels
      {
        path: '/stores/:storeId/print-labels',
        element: <StorePrintLabels />
      },

      // Chains: List
      {
        label: 'Kædeadministration',
        path: '/chains',
        type: 'link',
        element: (
          <>
            <ChainList />

            <Suspense>
              <Outlet />
            </Suspense>
          </>
        ),
        children: [
          // Chains: Create
          {
            label: 'Opret kæde',
            path: '/chains/create',
            element: <ChainCreate />
          },

          // Chains: Edit
          {
            label: 'Ret kæde',
            path: '/chains/:chainId',
            element: <ChainEdit />
          }
        ]
      }
    ]
  },

  {
    type: 'group',
    path: '',
    label: 'Todolister',
    allowed: [USER_ROLE.RETAILMANAGER_ADMINISTRATOR, USER_ROLE.TEAMLEDER_NAT],
    children: [

      // TODOLIST LIST
      {
        label: 'Oversigt',
        path: '/todolists',
        type: 'link',
        element: (
          <>
            <TodoListsList />
            
            <Suspense>
              <Outlet />
            </Suspense>
          </>
        ),
        children: [

          // TODOLIST SHOW
          {
            path: '/todolists/:todoListId',
            element: <TodoListItemsListByTodoList />,
          },

          // TODOLIST EDIT
          {
            path: '/todolists/:todoListId/edit',
            element: <TodoListItemsEdit />,
          },

          // TODOLIST FOLLOW UP
          {
            path: '/todolists/:todoListId/followup',
            element: <TodoListItemsFollowUp />,
          }

        ]
      },

      // TODOLIST ITEMS
      {
        label: 'Opgaver',
        path: '/todolists/items',
        type: 'link',
        element: <TodoListItemsListBySearch />
      },
    ]
  },


  //***** ROLE: ADMINISTRATOR *****
  // Reports dropdown
  {
    type: 'group',
    label: 'Rapporter',
    path: '',
    allowed: [USER_ROLE.ADMINISTRATOR],
    children: [

      // Category and brands report
      {
        label: 'Kategori og brands rapport',
        path: `${REST_API_URL}Report/CategoryAndBrandsReport`,
        type: 'download',
        allowed: []
      }
    ]
  },

  // DASHBOARD: Whistleblower
  {
    label: 'Whistleblower',
    path: '/Whistleblower',
    type: 'link',
    allowed: [USER_ROLE.EMPLOYEE, USER_ROLE.ADMINISTRATOR, USER_ROLE.TEAMLEDER_NAT, USER_ROLE.TEAMLEDER],
    element: <Whistleblower />
  },
];

export const getRoutesByRoles = (userRoles: string[]) : Route[] => {
  if (userRoles.length === 0) return [];

  return routeItems
    .filter(route => hasAccess(userRoles, route.allowed, route.denied))
    .map(route => ({
      ...route,
      id: Math.random().toString(36).substr(2, 9),
      type: route?.type || 'route',
      children: route.children
        ?.filter((route: Route) =>
          hasAccess(userRoles, route.allowed, route.denied)
        )
        .map(route => ({
          ...route,
          id: Math.random().toString(36).substr(2, 9),
          type: route?.type || 'route'
        }))
    }));
};

const hasAccess = (
  userRoles: string[],
  allowedRoles: string[] = [],
  deniedRoles: string[] = []
) => {
  let roleIsAllowed = true;
  if (allowedRoles && allowedRoles.length) {
    roleIsAllowed = roleMatch(userRoles, allowedRoles);
  }
  let roleIsDenied = false;
  if (deniedRoles && deniedRoles.length) {
    roleIsDenied = roleMatch(userRoles, deniedRoles);
  }

  return roleIsAllowed && !roleIsDenied;
};

const roleMatch = (userRoles: string[], allowedRoles: string[]) =>
  allowedRoles.some(allowedRole => userRoles.includes(allowedRole));

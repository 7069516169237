import { getAuthenticationToken } from 'Helpers/AuthHelper';
import axios from 'axios';
import download from 'downloadjs';

export const downloadAssignmentFile = (fileId, filename) =>
  downloadFile(filename, `AssignmentFile/DownloadFile?fileItemId=${fileId}`);

export const downloadAssignmentFiles = (assignmentId, filename) =>
  downloadFile(
    filename,
    `AssignmentFile/DownloadAssignmentFiles?assignmentId=${assignmentId}`
  );

export const downloadImagesByCampaign = (campaignId, filename) =>
  downloadFile(
    filename,
    `AssignmentFile/DownloadImages?campaignId=${campaignId}`
  );

export const downloadAnswersByCampaign = (campaignId, filename) =>
  downloadFile(
    filename,
    `File/DownloadAnswersByCampaign?campaignId=${campaignId}`
  );

export const downloadAnswersByCustomer = (customerId, filename) =>
  downloadFile(
    filename,
    `File/DownloadAnswersByCustomer?customerId=${customerId}`
  );

export const downloadImportTemplate = filename =>
  downloadFile(filename, `AssignmentFile/DownloadImportTemplate`);

export const downloadFile = (filename, url) =>
  new Promise(resolve => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getAuthenticationToken()}`
        },
        responseType: 'blob'
      })
      .then(response => {
        download(response.data, filename);
      })
      .catch(error => {})
      .finally(() => resolve());
  });

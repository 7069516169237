import {
  LS_AUTHENTICATION_KEY,
  LS_IMPERSONATION_KEY,
  LS_RETURN_URL_KEY
} from 'Constants';
import { isImpersonating } from './ImpersonateHelper';

const getAuthenticationLocalStorageKey = () => {
  return isImpersonating() ? LS_IMPERSONATION_KEY : LS_AUTHENTICATION_KEY;
};

const getAuthenticationDataFromLocalStorage = () => {
  try {
    const authKey = getAuthenticationLocalStorageKey();
    const authString = localStorage.getItem(authKey);
    const authJson = authString ? JSON.parse(authString) : null;
    return authJson;
  } catch {
    return null;
  }
};

export const getAuthenticationToken = () => {
  const userData = getAuthenticationDataFromLocalStorage();
  return userData && userData.authToken;
};

export const setAuthenticationData = ({
  key = LS_AUTHENTICATION_KEY,
  data
}) => {
  const name = data && data.name;
  const initials = getInitialsFromName(name);
  const enrichedData = {
    ...data,
    name,
    initials
  };

  localStorage.setItem(key, JSON.stringify(enrichedData));
};

const getInitialsFromName = name => {
  // Remove dashes and numbers from name and
  // texts in parentheses.
  // Hans Hansen (blomst)" -> "Hans Hansen"
  const names =
    name &&
    name
      .replace(/-/g, '')
      .replace(/[0-9]/g, '')
      .replace(/\([^()]*\)/gi, '')
      .trim()
      .split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

export const clearAuthenticationTokens = () => {
  localStorage.removeItem(LS_AUTHENTICATION_KEY);
  localStorage.removeItem(LS_IMPERSONATION_KEY);
};

export const getCurrentUserData = () => {
  const userData = getAuthenticationDataFromLocalStorage();
  return {
    username: userData && userData.username,
    name: userData && userData.name,
    initials: userData && userData.initials,
    hasOngoingCampaign: (userData && userData.hasOngoingCampaign) || false
  };
};

export const hardRedirectToSignIn = () => {
  clearAuthenticationTokens();
  localStorage.setItem(LS_RETURN_URL_KEY, window.location.pathname);
  window.location.href = `/signin`;
};

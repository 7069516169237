export const multiline = text => text && text.replace('\n', '<br/><br/>');
export const commaToPeriod = text => text && text.toString().replace(/,/g, '.');
export const periodToComma = text =>
  text && text.toString().replace(/\./g, ',');

export const objectToQueryString = object =>
  Object.keys(object)
    .filter(k => object[k] !== null)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(object[k]))
    .join('&');

export const isNull = obj => obj === null;
export const isUndefined = obj => typeof obj === 'undefined';
export const isUndefinedOrNull = obj => isUndefined(obj) || isNull(obj);

export const replaceCommaWithLinebreak = value =>
  value && value.replace(/,/g, '\n').replace(/ +?/g, '');
export const replaceLinebreakWithComma = value =>
  value && value.replace(/(?:\r\n|\r|\n)/g, ',').replace(/ +?/g, '');

export const removeRouteUrlPattern = value => value && value.split('/:')[0];

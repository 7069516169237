import '../../node_modules/react-dates/lib/css/_datepicker.css';
import '../../node_modules/react-day-picker/dist/style.css';

import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../../node_modules/@syncfusion/ej2-react-grids/styles/material.css';
import '../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css';

import '../../node_modules/react-image-gallery/styles/scss/image-gallery.scss';

import './Css/App.scss';
import './Css/Tailwind.scss';

export default function Styling() {
  return null;
}

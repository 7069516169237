import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Toolbar
} from '@mui/material';
import { IS_PROD_ENV } from 'Helpers/EnvHelper';
import { downloadFile } from 'Helpers/FileHelper';
import { useState } from 'react';
import { Link as RouteLink, NavLink } from 'react-router-dom';
import { Route } from 'Routes/routeItems';
import logo from 'Styling/logo.png';
import { TextDropdownMenu } from './Dropdowns/DropdownMenu';
import UserDropdown from './Dropdowns/UserDropdown';

export default function DesktopNavigation({ links }: { links: Route[] }) {
  return (
    <Box display="block" displayPrint="none">
      <AppBar>
        <Toolbar>
          <Grid container direction="row">
            <HomeLink />
            <NavigationItems links={links} />
            <UserDropdown />
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}

const NavigationItems = ({ links }: { links: Route[] }) => (
  <div style={{ flexGrow: 1 }}>
    {links.map(link =>
      link.type === 'group' ? (
        <DropdownMenu label={link.label} links={link.children} key={link.id} />
      ) : (
        <Button
          component={NavLink}
          sx={{
            color: '#D4D3D7',
            borderRadius: 16,
            fontWeight: 400,
            height: 32,
            margin: '5px 5px 0 0',
            padding: '0 15px',

            '&.active': {
              border: '1px solid rgba(255, 255, 255, 0.25)'
            }
          }}
          to={link.path}
          key={link.id}
        >
          {link.label}
        </Button>
      )
    )}
  </div>
);

const DropdownMenu = ({
  label,
  links
}: {
  label?: string;
  links?: Route[];
}) => (
  <TextDropdownMenu title={label}>
    {links?.map(link =>
      link.type === 'link' ? (
        // Internal link
        <MenuItem component={RouteLink} to={link.path} key={link.id}>
          {link.label}
        </MenuItem>
      ) : (
        // Download link
        <DownloadLink link={link} key={link.id} />
      )
    )}
  </TextDropdownMenu>
);

const DownloadLink = ({ link }: { link: Route }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    // Download link
    <MenuItem
      onClick={async event => {
        event.preventDefault();
        event.stopPropagation();
        setIsLoading(true);
        await downloadFile(link.label, link.path);
        setIsLoading(false);
      }}
      key={link.id}
    >
      <Box display="flex" alignItems="center">
        <Box marginRight={1.5}>{link.label}</Box>
        {isLoading && <CircularProgress size={16} />}
      </Box>
    </MenuItem>
  );
};

const HomeLink = () => (
  <Button
    component={NavLink}
    to="/"
    sx={{
      backgroundColor: '#fff',
      borderRadius: '20px',
      height: '40px',
      overflow: 'hidden',
      padding: 0,
      marginRight: {
        md: '30px'
      },

      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, .9)'
      }
    }}
  >
    {IS_PROD_ENV ? (
      <img
        src={logo}
        alt="Logo"
        style={{
          height: 25,
          margin: '0 20px',
          width: 'auto'
        }}
      />
    ) : (
      <Box
        sx={{
          background: '#ffeb3b',
          height: '40px',
          lineHeight: '40px',
          textAlign: 'center',
          width: '210px'
        }}
      >
        {import.meta.env.VITE_ENVIRONMENT}
      </Box>
    )}
  </Button>
);

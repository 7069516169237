import { gql } from '@apollo/client';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { apolloClient } from 'ApolloClient';
import Navigation from 'Components/Global/Navigation/Navigation';
import { USER_ROLE } from 'Constants';
import AuthContextProvider from 'Contexts/AuthContext';
import { getCurrentUserData } from 'Helpers/AuthHelper';
import React, { Suspense } from 'react';
import {
  createBrowserRouter,
  Outlet,
  RouteObject,
  RouterProvider,
  useLoaderData,
  useRoutes
} from 'react-router-dom';
import RouteError from './RouteError';
import { getRoutesByRoles } from './routeItems';
import RouteNotFound from './RouteNotFound';
const SignIn = React.lazy(() => import('Components/Account/Account-SignIn'));
const SmsAuth = React.lazy(() => import('Components/Account/Account-SmsAuth'));
const ForgotPassword = React.lazy(
  () => import('Components/Account/Account-ForgotPassword')
);
const Activate = React.lazy(
  () => import('Components/Account/Account-Activate')
);

export default function Routes() {
  return <RouterProvider router={router} />;
}

const LOAD_USER_GQL = gql`
  query {
    account_LoadCurrentUser {
      userName

      roles {
        name
      }
    }
  }
`;

const fetchUser = () =>
  apolloClient.query({
    query: LOAD_USER_GQL,
    context: {
      fetchPolicy: 'no-cache'
    }
  });

const PrivateRoutes = ({ children }: { children: React.ReactNode }) => {
  const { data }: any = useLoaderData();
  const user = data?.account_LoadCurrentUser;
  const userRoles = handleUserRoles(user);
  const routes = getRoutesByRoles(userRoles) as RouteObject[];
  const element = useRoutes([
    {
      id: 'private-routes',
      element: children,
      errorElement: <RouteError />,
      children: [
        ...routes,
        {
          path: '*',
          element: <RouteNotFound />
        }
      ]
    }
  ]);

  return <AuthContextProvider roles={userRoles}>{element}</AuthContextProvider>;
};

const handleUserRoles = (user: any): string[] => {
  const userRoles = (user && user.roles.map(r => r.name)) || [];
  // If the user is assigned to an "ongoing" campaign, then
  // we add him/her to the "EMPLOYEE_ONGOING_CAMPAIGN" role.
  // That role only exists on the client and is used to
  // grant the user access to certain routes.
  const { hasOngoingCampaign } = getCurrentUserData();
  if (hasOngoingCampaign) {
    userRoles.push(USER_ROLE.EMPLOYEE_ONGOING_CAMPAIGN);
  }
  return userRoles;
};

export const router = createBrowserRouter([
  {
    id: 'root',
    element: <Outlet />,
    errorElement: <RouteError />,
    children: [
      // public routes
      {
        id: 'signin',
        path: '/signin',
        element: (
          <Suspense fallback={<LinearProgress />}>
            <SignIn />
          </Suspense>
        )
      },
      {
        id: 'sms-auth',
        path: '/sms-auth',
        element: (
          <Suspense fallback={<LinearProgress />}>
            <SmsAuth />
          </Suspense>
        )
      },
      {
        id: 'forgot-password',
        path: '/forgot-password',
        element: (
          <Suspense fallback={<LinearProgress />}>
            <ForgotPassword />
          </Suspense>
        )
      },
      {
        id: 'activate',
        path: '/activate/:username/:activationCode',
        element: (
          <Suspense fallback={<LinearProgress />}>
            <Activate />
          </Suspense>
        )
      },

      // private routes
      {
        id: 'private',
        path: '*',
        loader: fetchUser,
        errorElement: <RouteError />,
        element: (
          <PrivateRoutes>
            <Navigation />
            <Suspense fallback={<LinearProgress />}>
              <Box p={2.5}>
                <Outlet />
              </Box>
            </Suspense>
          </PrivateRoutes>
        )
      }
    ]
  }
]);

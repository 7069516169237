import Box from '@mui/material/Box';
import { hardRedirectToSignIn } from 'Helpers/AuthHelper';
import { useEffect } from 'react';

const RouteNotAuthorized = () => {
  useEffect(() => {
    hardRedirectToSignIn();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        fontSize: 24,
        marginTop: 10,
        justifyContent: 'center'
      }}
    >
      <span>Viderestiller til login siden.</span>
    </Box>
  );
};

export default RouteNotAuthorized;

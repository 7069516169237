import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { IS_DEV_ENV, IS_PROD_ENV } from 'Helpers/EnvHelper';

export default class ErrorBoundary extends Component<{
  children?: React.ReactNode;
}> {
  state: {
    hasError: boolean;
    error: any;
    errorInfo: any;
  } = {
    hasError: false,
    error: undefined,
    errorInfo: null
  };

  componentDidMount() {
    // Elevate failed prop type validation to throw an error
    // and not just log it to the console.
    if (IS_DEV_ENV) {
      const error = console.error;
      console.error = function (warning, ...args) {
        if (/(Invalid prop|Failed prop type)/.test(warning)) {
          throw new Error(warning);
        }
        error.apply(console, [warning, ...args]);
      };
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Log to Sentry
    if (IS_PROD_ENV) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }

    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ fontFamily: 'Courier New', padding: 20, width: 800 }}>
          <h1>Ups! Der opstod en fejl :(</h1>
          <strong>{this.state.error && this.state.error.toString()}</strong>
          <pre>{this.state.errorInfo.componentStack}</pre>
        </div>
      );
    }
    return this.props.children;
  }
}

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { OperationDefinitionNode } from 'graphql';
import { GRAPHQL_API_URL } from './Constants';
import { getAuthenticationToken } from './Helpers/AuthHelper';

// Remove __typename from mutation variables
// The __typename field is added to data returned by Apollo queries.
// So if fetch data, modify it and send it back to the server,
// the __typename field will also be sent along.
// That can cause problems with the server, so we remove it here.
const cleanTypeName = new ApolloLink((operation, forward) => {
  const omitTypename = (key: any, value: any) =>
    key === '__typename' ? undefined : value;

  const def = getMainDefinition(operation.query);
  if (def && (<OperationDefinitionNode>def).operation === 'mutation') {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward ? forward(operation) : null;
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getAuthenticationToken();
  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`
    }
  });
  return forward(operation);
});

const httpLink = createHttpLink({
  uri: GRAPHQL_API_URL
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authMiddleware, cleanTypeName, httpLink]),
  cache: new InMemoryCache()
});

import { useEffect } from 'react';
import { IS_DEV_ENV } from 'Helpers/EnvHelper';

export default function Favicon() {
  // Change favicon in dev mode
  useEffect(() => {
    if (IS_DEV_ENV) {
      const shortcut = document.querySelector(
        'link[rel="shortcut icon"]'
      ) as HTMLLinkElement;
      const icon = document.querySelector(
        'link[rel="icon"]'
      ) as HTMLLinkElement;

      shortcut.setAttribute('href', '/favicon-dev.png');
      icon.setAttribute('href', '/favicon-dev.png');
    }
  }, [IS_DEV_ENV]);

  return null;
}

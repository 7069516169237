import Box from '@mui/material/Box';

const RouteNotFound = () => (
  <Box
    sx={{
      display: 'flex',
      fontSize: 24,
      marginTop: 10,
      justifyContent: 'center'
    }}
  >
    <span>404 - ikke fundet</span>
  </Box>
);

export default RouteNotFound;

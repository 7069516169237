import { ApolloProvider } from '@apollo/react-hooks';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Toast, ToastContainer } from './Components/Global/Toast';
import 'react-dates/initialize';
import { ToastProvider } from 'react-toast-notifications';
import { apolloClient } from './ApolloClient';
import ErrorBoundary from './ErrorBoundary';
import { getMuiTheme } from './Styling/MuiThemes/muiTheme';
import StyleLoader from './Styling/StyleLoader';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { registerLicense } from '@syncfusion/ej2-base';
import axios from 'axios';
import { REST_API_URL } from 'Constants';
import { getUserPosition } from 'Helpers/PositionHelper';
import 'moment/locale/da';
import Routes from 'Routes/Routes';
import { setEj2Culture, setEj2Texts } from 'Syncfusion/Ej2Config';
import { initSentry, enrichSentry } from './Sentry';
import localeDa from 'date-fns/locale/da';
import { LicenseInfo } from '@mui/x-license-pro';
import Favicon from 'Favicon';

// MUI License
LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY);

// Initialize Sentry
initSentry();

// Apollo client
axios.defaults.baseURL = REST_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

// Syncfusion License
registerLicense(import.meta.env.VITE_SYNCFUSION_LICENSE_KEY);

// Syncfusion ej2 localization
setEj2Culture();
setEj2Texts();

// Theory: Call getCurrentPosition to "warm up GPS",
// so it will be ready later when we need it.
// .catch() to avoid "Uncaught (in promise)" error.
getUserPosition().catch(() => {});

export default function App() {
  const theme = getMuiTheme();

  // Enrich trackers with user data
  enrichSentry();

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={4000}
          components={{ ToastContainer, Toast }}
        >
          <CssBaseline />
          <StyleLoader />
          <Favicon />
          <ErrorBoundary>
            <LocalizationProvider
              adapterLocale={localeDa}
              dateAdapter={AdapterDateFns}
            >
              <Routes />
            </LocalizationProvider>
          </ErrorBoundary>
        </ToastProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

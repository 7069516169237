import { apolloClient } from '../ApolloClient';
import { gql } from '@apollo/client';
import { POSITION_ERROR } from '../PositionConstants';
import { convertToServerDateTime } from './DateHelper';
import { getAccurateCurrentPosition } from '../GeoLocation/AccurateCurrentPosition';
import { formHasErrors, getFormErrors } from 'Hooks/useSave';

// Source file from before AccurateCurrentPosition:
// https://raw.githubusercontent.com/Eventforce/RetailManager-Client/develop/src/Helpers/PositionHelper.js?token=AAZC25M76G2O5VFM4WTTT427BH4M4

export const getUserPosition = () =>
  new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      getAccurateCurrentPosition(
        position => {
          resolve({
            latitude: position.coords.latitude.toString(),
            longitude: position.coords.longitude.toString(),
            errorCode: null,
            errorMessage: null
          });
        },
        error =>
          reject({
            errorCode: error?.code,
            errorMessage: error?.message
          })
      );
    } else {
      reject({
        errorCode: POSITION_ERROR.NOT_SUPPORTED,
        errorMessage: null
      });
    }
  });

export const findAndSetAssignmentPosition = ({ assignmentId }) =>
  new Promise((resolve, reject) =>
    getUserPosition()
      .then((result: any) => {
        setAssignmentPosition(
          assignmentId,
          result.latitude,
          result.longitude,
          'browser',
          convertToServerDateTime()
        )
          .then(response => {
            if (formHasErrors(response.data)) {
              reject(getFormErrors(response.data));
            } else {
              resolve(response.data);
            }
          })
          .catch(error => reject(error));
      })
      .catch(error => reject(error))
  );

export const setAssignmentPosition = (
  assignmentId,
  latitude,
  longitude,
  source,
  timestamp
) => {
  const note = navigator.userAgent;

  return apolloClient.mutate({
    variables: {
      assignmentId,
      latitude,
      longitude,
      source,
      note,
      timestamp
    },
    mutation: gql`
      mutation assignmentPositionLog_Save(
        $assignmentId: Int!
        $latitude: String
        $longitude: String
        $source: String
        $note: String
        $timestamp: DateTime
      ) {
        assignmentPositionLog_Save(
          obj: {
            assignmentId: $assignmentId
            latitude: $latitude
            longitude: $longitude
            source: $source
            note: $note
            clientTimestamp: $timestamp
          }
        ) {
          hasError
          returnObject
          validationResult
        }
      }
    `
  });
};

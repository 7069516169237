import { RefetchQueriesInclude } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { apolloClient } from 'ApolloClient';
import { to } from 'Helpers/AsyncHelper';
import { formatErrorArray } from 'Helpers/ErrorHelper';
import { To, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

type useValidateAndSaveProps = {
  VALIDATE_GQL: any;
  SAVE_GQL: any;
  refetch?: RefetchQueriesInclude;
  redirect?: To | number;
};

type validateAndSaveProps = {
  mutationVariables: any;
  setSubmitting?: () => void;
  successText?: string;
};

export const useValidateAndSave = ({
  VALIDATE_GQL,
  SAVE_GQL,
  refetch,
  redirect
}: useValidateAndSaveProps) => {
  const { validate } = useValidate({ VALIDATE_GQL });
  const { save } = useSave({ SAVE_GQL });
  const navigate = useNavigate();

  const validateAndSave = async ({
    mutationVariables,
    setSubmitting,
    successText
  }: validateAndSaveProps): Promise<SaveResult> => {
    // Validate
    const valiationResult = await validate({
      mutationVariables,
      setSubmitting
    });
    if (!valiationResult.success) {
      return valiationResult;
    }

    // Save
    const saveResult = await save({
      mutationVariables,
      setSubmitting,
      successText
    });

    // Refetch data
    if (refetch) {
      await apolloClient.refetchQueries({
        include: refetch
      });
    }

    if (redirect) {
      if (Number.isInteger(redirect)) {
        navigate(+redirect);
      } else {
        navigate(redirect.toString());
      }
    }

    return saveResult;
  };

  return { validateAndSave };
};

type ValidateResult = {
  success: boolean;
  data?: any;
  error?: any;
};

const useValidate = ({ VALIDATE_GQL }) => {
  const [validateMutation] = useMutation(VALIDATE_GQL);
  const { formError } = useFormStateChanged();

  const validate = async ({
    mutationVariables,
    setSubmitting
  }): Promise<ValidateResult> => {
    // Validate
    const [error, { data }] = await to(
      validateMutation({
        variables: mutationVariables
      })
    );

    // Validation failed
    if (formHasErrors(data, error)) {
      formError({
        data,
        error,
        setSubmitting
      });

      return {
        success: false,
        data,
        error: getFormErrors(data, error)
      };
    }

    return {
      success: true,
      data
    };
  };

  return { validate };
};

export type useSaveProps = {
  SAVE_GQL: any;
  refetch?: RefetchQueriesInclude;
  redirect?: To | number;
};

export type SaveResult = {
  success: boolean;
  data?: any;
  error?: any;
};

export const useSave = ({ SAVE_GQL, refetch, redirect }: useSaveProps) => {
  const [saveMutation] = useMutation(SAVE_GQL);
  const { formSuccess, formError } = useFormStateChanged();
  const navigate = useNavigate();

  const save = async ({
    mutationVariables,
    setSubmitting,
    successText
  }: {
    mutationVariables: any;
    setSubmitting?: () => void;
    successText?: string;
  }): Promise<SaveResult> => {
    // Save
    const [error, { data }] = await to(
      saveMutation({
        variables: mutationVariables
      })
    );

    if (formHasErrors(data, error)) {
      // Save failed
      formError({
        data,
        error,
        setSubmitting
      });

      return {
        success: false,
        data,
        error: getFormErrors(data, error)
      };
    }

    // Save succeeded
    if (successText) {
      formSuccess({
        text: successText,
        setSubmitting
      });
    }

    // Refetch data
    if (refetch) {
      await apolloClient.refetchQueries({
        include: refetch
      });
    }

    if (redirect) {
      if (Number.isInteger(redirect)) {
        navigate(+redirect);
      } else {
        navigate(redirect.toString());
      }
    }

    return {
      success: true,
      data,
      error
    };
  };

  return { save };
};

export const getFormErrors = (data: any, error?: any) => {
  let errors: any[] = [];

  // Serverside validation errors
  if (data) {
    errors = Object.keys(data)
      .filter(key => data[key].hasError)
      .reduce((previous: any[], current) => {
        previous.push(...data[current].validationResult);
        return previous;
      }, []);
  }

  // GraphQL error
  if (error) {
    errors.push(error ? error.message : error);
  }

  return errors;
};

export const formHasErrors = (data: any, error?: any) =>
  getFormErrors(data, error).length;

const useFormStateChanged = () => {
  const { addToast } = useToasts();

  const formSuccess = ({ text, setSubmitting }) => {
    if (text) {
      addToast(text, {
        appearance: 'success',
        autoDismiss: true
      });
    }

    if (setSubmitting) {
      setSubmitting(false);
    }
  };

  const formError = ({ data, error, setSubmitting }) => {
    const errors = getFormErrors(data, error);

    if (errors.length) {
      addToast(formatErrorArray(errors), {
        appearance: 'error',
        autoDismiss: true,
        // @ts-ignore
        autoDismissTimeout: 7000
      });

      if (setSubmitting) {
        setSubmitting(false);
      }
    }
  };

  return {
    formSuccess,
    formError
  };
};

import { getCurrentUserData } from 'Helpers/AuthHelper';
import { IS_DEV_ENV } from 'Helpers/EnvHelper';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const initSentry = () => {
  if (IS_DEV_ENV) return;

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
};

export const enrichSentry = () => {
  if (IS_DEV_ENV) return;

  // const { username } = getCurrentUserData();
  // if (username) {
  //   console.log('Adding username to Sentry', username);

  //   // Add username to the trackers
  //   Sentry.configureScope(function (scope) {
  //     scope.setUser({ username });
  //   });
  // }
};

import React, { createContext } from 'react';
import { USER_ROLE } from 'Constants';

type AuthContextType = {
  roles: typeof USER_ROLE[];
  isAdministrator: boolean;
  isCustomer: boolean;
  isEmployee: boolean;
  isRetailManagerAdministrator: boolean;
  isTeamlederNat: boolean;
  isCampaignAdministrator: boolean;
};

export const AuthContext = createContext<AuthContextType>({
  roles: [],
  isAdministrator: false,
  isCustomer: false,
  isEmployee: false,
  isRetailManagerAdministrator: false,
  isTeamlederNat: false,
  isCampaignAdministrator: false
});

export default function AuthContextProvider({ roles, children }) {
  const isAdministrator = roles.includes(USER_ROLE.ADMINISTRATOR);
  const isRetailManagerAdministrator = roles.includes(
    USER_ROLE.RETAILMANAGER_ADMINISTRATOR
  );
  const isCustomer = roles.includes(USER_ROLE.CUSTOMER);
  const isTeamlederNat = roles.includes(USER_ROLE.TEAMLEDER_NAT);
  const isCampaignAdministrator = roles.includes(
    USER_ROLE.CAMPAIGN_ADMINISTRATOR
  );
  const isEmployee = roles.includes(USER_ROLE.EMPLOYEE);

  return (
    <AuthContext.Provider
      value={{
        roles,
        isAdministrator,
        isCustomer,
        isEmployee,
        isRetailManagerAdministrator,
        isTeamlederNat,
        isCampaignAdministrator
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

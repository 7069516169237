import { faBars } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  SwipeableDrawer,
  Toolbar
} from '@mui/material';
import { IS_PROD_ENV } from 'Helpers/EnvHelper';
import React, { useState } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  NavLink
} from 'react-router-dom';

import { Route } from 'Routes/routeItems';
import logo from 'Styling/logo.png';
import UserDropdown from './Dropdowns/UserDropdown';

export default function MobileNavigation({ links }: { links: Route[] }) {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <React.Fragment>
      <Box display="block" displayPrint="none">
        <AppBar>
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <DrawerTrigger
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
              />
              <HomeLink />
              <UserDropdown />
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </Box>

      <MobileDrawer
        links={links}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
      />
    </React.Fragment>
  );
}

const DrawerTrigger = ({ openDrawer, setOpenDrawer }) => (
  <IconButton
    disableRipple={true}
    onClick={() => setOpenDrawer(!openDrawer)}
    size="large"
    sx={{
      backgroundColor: '#eee',
      height: '40px',
      width: '40px',

      '&:hover': {
        backgroundColor: '#fff'
      }
    }}
  >
    <FontAwesomeIcon color="#000" icon={faBars} size="xs" />
  </IconButton>
);

const HomeLink = () => (
  <Button
    component={NavLink}
    to="/"
    sx={{
      backgroundColor: '#fff',
      borderRadius: '20px',
      height: '40px',
      overflow: 'hidden',
      padding: 0
    }}
  >
    {IS_PROD_ENV ? (
      <img
        src={logo}
        alt="Logo"
        style={{
          height: 25,
          margin: '0 20px',
          width: 'auto'
        }}
      />
    ) : (
      <Box
        sx={{
          background: '#ffeb3b',
          height: '40px',
          lineHeight: '40px',
          textAlign: 'center',
          width: '210px'
        }}
      >
        {import.meta.env.VITE_ENVIRONMENT}
      </Box>
    )}
  </Button>
);

const MobileDrawer = ({
  links,
  openDrawer,
  setOpenDrawer
}: {
  links: Route[];
  openDrawer: boolean;
  setOpenDrawer: (openDrawer: boolean) => void;
}) => (
  <SwipeableDrawer
    open={openDrawer}
    onClose={() => setOpenDrawer(false)}
    onOpen={() => setOpenDrawer(true)}
  >
    <div
      tabIndex={0}
      role="button"
      onClick={() => setOpenDrawer(false)}
      onKeyDown={() => setOpenDrawer(false)}
    >
      <List>
        {links.map(link => (
          <React.Fragment key={link.id}>
            <ListItem
              component={Link}
              button
              disabled={!!link.children?.length}
              to={link?.path}
              divider
              sx={{
                fontSize: 14,
                marginTop: 0,
                marginBottom: 1
              }}
            >
              {link.label}
            </ListItem>

            {link?.children?.map(child => (
              <ListItem
                button
                component={Link}
                to={child.path}
                sx={{
                  fontSize: 14
                }}
                key={child.id}
              >
                - {child.label}
              </ListItem>
            ))}
          </React.Fragment>
        ))}
      </List>
    </div>
  </SwipeableDrawer>
);

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(
  itemProps,
  ref
) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

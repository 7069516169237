import moment from 'moment';
import { format } from 'date-fns';

export const formatDate = date => moment(date).format('DD-MM-YYYY');
export const formatDateTime = date => moment(date).format('DD-MM-YYYY HH:mm');
export const prettyDate = date => moment(date).format('D. MMMM YYYY');
export const prettyDateTime = date =>
  moment(date).format('D. MMMM YYYY HH:mm:ss');

export const convertToServerDate = date => moment(date).format('YYYY-MM-DD');
export const convertToServerDateTime = date =>
  moment(date).format('YYYY-MM-DD HH:mm:ss');

export const getCurrentWeek = () =>
  format(new Date(), 'w-yyyy', {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  });

import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, IconButton, Menu } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';

export const TextDropdownMenu = ({ title, children }) => {
  const trigger = (
    <Button
      sx={{
        color: '#D4D3D7',
        borderRadius: 16,
        fontWeight: 400,
        height: 32,
        margin: '5px 10px 0 0',
        padding: '0 15px',

        '&.active': {
          border: '1px solid rgba(255, 255, 255, 0.25)'
        }
      }}
    >
      {title}
      <Box
        sx={{
          marginLeft: '5px',
          marginTop: '2px'
        }}
      >
        <FontAwesomeIcon icon={faAngleDown} />
      </Box>
    </Button>
  );

  return <DropdownMenu trigger={trigger}>{children}</DropdownMenu>;
};

export const AvatarDropdownMenu = ({ text, children }) => {
  const trigger = (
    <IconButton
      color="inherit"
      sx={{
        height: 40,
        width: 40
      }}
      size="large"
    >
      <Avatar
        sx={theme => ({
          backgroundColor: '#fff',
          color: theme.palette.primary.dark
        })}
      >
        {text}
      </Avatar>
    </IconButton>
  );

  return <DropdownMenu trigger={trigger}>{children}</DropdownMenu>;
};

type Props = {
  trigger: JSX.Element;
  children: React.ReactNode;
};

const DropdownMenu = ({ trigger, children }: Props) => (
  <PopupState variant="popover" popupId="popupmenu">
    {popupState => (
      <React.Fragment>
        {React.cloneElement(trigger, { ...bindTrigger(popupState) })}

        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClick={() => popupState.close()}
          {...bindMenu(popupState)}
        >
          {children}
        </Menu>
      </React.Fragment>
    )}
  </PopupState>
);

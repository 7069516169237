import { loadCldr, setCulture, L10n } from '@syncfusion/ej2-base';
import * as gregorian from 'cldr-data/main/da/ca-gregorian.json';
import * as numbers from 'cldr-data/main/da/numbers.json';
import * as timeZoneNames from 'cldr-data/main/da/timeZoneNames.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';

export const setEj2Culture = () => {
  loadCldr(gregorian, numbers, timeZoneNames, numberingSystems, weekData);
  setCulture('da');
};

export const setEj2Texts = () => {
  L10n.load({
    da: {
      grid: {
        EmptyRecord: '<span>Din søgning gav ingen resultater!</span>',
        EmptyDataSourceError: 'Der opstod en fejl',
        Item: 'Emne',
        Items: 'Emner'
      },
      pager: {
        currentPageInfo: '{0} af {1} sider',
        totalItemsInfo: '({0} emner)',
        firstPageTooltip: 'Første side',
        lastPageTooltip: 'Sidste side',
        nextPageTooltip: 'Frem',
        previousPageTooltip: 'Tilbage',
        nextPagerTooltip: 'Spring frem',
        previousPagerTooltip: 'Spring tilbage',
        pagerDropDown: 'Emner pr side'
      },
      schedule: {
        day: 'Dag',
        week: 'Uge',
        workWeek: 'Arbejdsuge',
        month: 'Måned',
        agenda: 'Agenda',
        weekAgenda: 'Uge agenda',
        workWeekAgenda: 'Arbejdsuge agenda',
        monthAgenda: 'Måned agenda',
        today: 'I dag',
        noEvents: 'Ingen opgaver',
        emptyContainer: 'Der er ingen opgaver på denne dag.',
        allDay: 'Hele dagen',
        start: 'Start',
        end: 'Slut',
        more: 'mere',
        close: 'Luk',
        cancel: 'Annuller',
        noTitle: '(Ingen overskrift)',
        delete: 'Slet',
        deleteEvent: 'Slet opgave',
        deleteMultipleEvent: 'Slet flere opgaver',
        selectedItems: 'Opgaver valgt',
        deleteSeries: 'Slet serie',
        edit: 'Ret',
        editSeries: 'Ret serie',
        editEvent: 'Ret opgave',
        createEvent: 'Opret',
        subject: 'Emne',
        addTitle: 'Tilføj titel',
        moreDetails: 'Flere detaljer',
        save: 'Gem',
        editContent: 'Do you want to edit only this event or entire series?',
        deleteRecurrenceContent:
          'Do you want to delete only this event or entire series?',
        deleteContent: 'Are you sure you want to delete this event?',
        deleteMultipleContent:
          'Are you sure you want to delete the selected events?',
        newEvent: 'Ny opgave',
        title: 'Titel',
        location: 'Location',
        description: 'Description',
        timezone: 'Timezone',
        startTimezone: 'Start Timezone',
        endTimezone: 'End Timezone',
        repeat: 'Gentag',
        saveButton: 'Gem',
        cancelButton: 'Annuller',
        deleteButton: 'Slet',
        recurrence: 'Recurrence',
        wrongPattern: 'The recurrence pattern is not valid.',
        seriesChangeAlert:
          'The changes made to specific instances of this series will be cancelled and those events will match the series again.',
        createError:
          'The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
        recurrenceDateValidation:
          'Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.',
        sameDayAlert:
          'Two occurrences of the same event cannot occur on the same day.',
        editRecurrence: 'Edit Recurrence',
        repeats: 'Repeats',
        alert: 'Alert',
        startEndError: 'The selected end date occurs before the start date.',
        invalidDateError: 'The entered date value is invalid.',
        ok: 'Ok',
        occurrence: 'Occurrence',
        series: 'Serie',
        previous: 'Tilbage',
        next: 'Frem',
        timelineDay: 'Tidslinje dag',
        timelineWeek: 'Tidslinje uge',
        timelineWorkWeek: 'Tidslinje arbejdsuge',
        timelineMonth: 'Tidslinje måned',
        timelineYear: 'År'
      },
      calendar: {
        today: 'I dag'
      },
      dropdowns: {
        noRecordsTemplate: 'Ingen resultater'
      },
      recurrenceeditor: {
        none: 'None',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
        month: 'Month',
        yearly: 'Yearly',
        never: 'Never',
        until: 'Until',
        count: 'Count',
        first: 'First',
        second: 'Second',
        third: 'Third',
        fourth: 'Fourth',
        last: 'Last',
        repeat: 'Repeat',
        repeatEvery: 'Repeat Every',
        on: 'Repeat On',
        end: 'End',
        onDay: 'Day',
        days: 'Day(s)',
        weeks: 'Week(s)',
        months: 'Month(s)',
        years: 'Year(s)',
        every: 'every',
        summaryTimes: 'time(s)',
        summaryOn: 'on',
        summaryUntil: 'until',
        summaryRepeat: 'Repeats',
        summaryDay: 'day(s)',
        summaryWeek: 'week(s)',
        summaryMonth: 'month(s)',
        summaryYear: 'year(s)'
      }
    }
  });
};

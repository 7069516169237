import { AuthContext } from 'Contexts/AuthContext';
import { useIsDesktop } from 'Helpers/BreakpointHelper';
import { removeRouteUrlPattern } from 'Helpers/StringHelper';
import { useContext } from 'react';
import { getRoutesByRoles } from 'Routes/routeItems';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

export default function Navigation() {
  const { roles } = useContext(AuthContext);
  const isDesktop = useIsDesktop('lg');
  const links = getLinks(roles);

  return isDesktop ? (
    <DesktopNavigation links={links} />
  ) : (
    <MobileNavigation links={links} />
  );
}

// Get route items allowed by the user's role and
// only get of the type "link" and "group".
const getLinks = roles =>
  getRoutesByRoles(roles)
    .filter(link => link.type !== 'route')
    .map(link => {
      if (link.children) {
        link.children = link.children
          .filter(child => child.type !== 'route')
          .map(child => ({
            ...child,

            // The route URL pattern is only used by React Router
            // and should be part of the navigation URLs.
            path: removeRouteUrlPattern(child.path)
          }));
      }

      // The route URL pattern is only used by React Router
      // and should be part of the navigation URLs.
      link.path = removeRouteUrlPattern(link.path);
      return link;
    });

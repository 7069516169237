import { isApolloError, ServerError } from '@apollo/client';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IS_PREPROD_ENV, IS_PROD_ENV } from 'Helpers/EnvHelper';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import UpdatePrompt from 'UpdatePrompt';
import RouteNotAuthorized from './RouteNotAuthorized';
import RouteNotFound from './RouteNotFound';

// Source: https://github.com/remix-run/react-router/blob/230d9e5539c410c0c747db8670ec5de1d51558ae/packages/react-router/lib/hooks.tsx#L455
export default function RouteError() {
  const error: any = useRouteError();
  const routeStatus = isRouteErrorResponse(error) ? error.status : null;
  const message = isRouteErrorResponse(error)
    ? `${error.status} ${error.statusText}`
    : error instanceof Error
    ? error.message
    : JSON.stringify(error);
  const stack = error instanceof Error ? error.stack : null;
  const serverStatus =
    isApolloError(error) &&
    error.networkError &&
    (error.networkError as ServerError).statusCode;

  const status = serverStatus || routeStatus;

  // Not authorized
  if (status === 401 || status === 403) {
    return <RouteNotAuthorized />;
  }

  // Not found
  if (status === 404) {
    return <RouteNotFound />;
  }

  // An error occurred, it might be caused by a new version
  // being deployed. Suggest the user to reload the app.
  if (
    (IS_PROD_ENV || IS_PREPROD_ENV) &&
    !window.location.href.includes('?reloaded')
  ) {
    return <UpdatePrompt />;
  }

  return (
    <Box p={5}>
      <Typography variant="h1" mb={2}>
        Ups! Der opstod en fejl :(
      </Typography>

      <Typography variant="h3" mt={0} mb={3}>
        {message}
      </Typography>

      {stack ? <pre style={{ color: '#000' }}>{stack}</pre> : null}
    </Box>
  );
}

import React from 'react';
import {
  DefaultToastContainer,
  DefaultToast,
  ToastProps
} from 'react-toast-notifications';
import { useIsDesktop } from 'Helpers/BreakpointHelper';
import { Box } from '@mui/material';

export const ToastContainer = props => {
  const isDesktop = useIsDesktop();

  return (
    <DefaultToastContainer
      style={{
        maxWidth: '100%',
        right: isDesktop ? 90 : 5,
        zIndex: 9999
      }}
      {...props}
    />
  );
};

export const Toast = (props: ToastProps) => {
  const isDesktop = useIsDesktop();
  const largeToast = props.appearance === 'error' && isDesktop;
  if (!props.children) return null;

  // Can't pass "style" directly as a prop to DefaultToast,
  const settings = {
    ...props,
    style: {
      marginTop: 6,
      width: 'auto'
    }
  };

  return (
    <DefaultToast {...settings}>
      <React.Fragment>
        {largeToast ? (
          <pre
            style={{
              margin: 0,
              maxWidth: 700,
              overflowX: 'auto'
            }}
            dangerouslySetInnerHTML={{ __html: props.children.toString() }}
          />
        ) : (
          <Box
            sx={{
              fontFamily: props.appearance === 'error' ? 'monospace' : undefined
            }}
            dangerouslySetInnerHTML={{ __html: props.children.toString() }}
          />
        )}
      </React.Fragment>
    </DefaultToast>
  );
};

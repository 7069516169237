export const POSITION_ERROR = Object.freeze({
  // Custom errors
  NO_VALID_POSITION: -3,
  NOT_SUPPORTED: -2,
  SERVER_ERROR: -1,
  UNKNOWN_ERROR: 0,

  // Browser errors (GeolocationPositionError)
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3
});

export const getPositionErrorKeyByValue = value =>
  Object.keys(POSITION_ERROR).find(key => POSITION_ERROR[key] === value);

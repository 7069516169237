import { createTheme } from '@mui/material/styles';
import pink from '@mui/material/colors/pink';

const defaultTheme = createTheme();

export const RM_PRIMARY_COLOR = '#3b82ec';
export const RM_PRIMARY_COLOR_LIGHT = '#ddd';
export const RM_CONTRAST_COLOR = '#293042';

export const getMuiTheme = () => {
  return createTheme({
    palette: {
      primary: {
        main: RM_PRIMARY_COLOR,
        light: RM_PRIMARY_COLOR_LIGHT
      },
      secondary: pink,
      background: {
        default: '#f2f6f9',
        paper: '#fff'
      }
    },
    typography: {
      fontSize: 13,

      h1: {
        color: '#000',
        fontSize: 24,
        fontWeight: 500,
        marginBottom: 20
      },
      h2: {
        color: '#000',
        fontSize: 20,
        fontWeight: 400,
        marginBottom: 10
      },
      h3: {
        color: '#000',
        fontSize: 18,
        fontWeight: 400,
        marginBottom: 0,
        marginTop: 20
      },
      h4: {
        color: '#000',
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 20,
        marginTop: 0
      },
      h5: {
        color: '#000',
        fontSize: 15,
        fontWeight: 400,
        marginBottom: 20,
        marginTop: 0
      },
      body1: {
        fontSize: 13
      }
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow:
              '0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04)',
            maxHeight: 64
          },
          colorPrimary: {
            backgroundColor: RM_CONTRAST_COLOR
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: 14,
            minHeight: 36
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard'
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: 13
          }
        }
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true
        },
        styleOverrides: {
          root: {
            color: 'rgba(0, 0, 0, 0.87)',
            minWidth: 0,
            textTransform: 'none'
          },
          containedPrimary: {
            color: '#fff'
          },
          containedSecondary: {
            color: '#fff'
          },
          outlined: {
            backgroundColor: '#fff'
          },
          outlinedPrimary: {
            backgroundColor: '#fff',
            borderColor: 'rgba(0, 0, 0, 0.23)',

            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, .5)'
            }
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&$disabled': {
              backgroundColor: '#eee !important'
            }
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              color: pink[600]
            }
          }
        }
      },

      MuiRadio: {
        styleOverrides: {
          root: {
            padding: '5px 9px'
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: 13
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            display: 'inline-block'
          },
          formControl: {
            // Avoid label getting hidden behind an input field
            // with a solid background color.
            // zIndex: 1
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: '#f8f8f8'
          },
          flexContainer: {
            flexWrap: 'wrap'
          },
          indicator: {
            height: 0
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            borderRight: '1px solid rgba(0,0,0,.04)',
            borderBottom: '1px solid rgba(0,0,0,.04)',
            fontSize: 12.5,
            letterSpacing: 0,
            padding: '6px 20px',
            textTransform: 'none',
            [defaultTheme.breakpoints.up('md')]: {
              minWidth: 120
            },

            '&.Mui-selected': {
              '&::before': {
                content: "'before'",
                backgroundColor: RM_PRIMARY_COLOR,
                height: 2,
                width: '100%',
                position: 'absolute',
                bottom: 0,
                left: 0
              }
            },
            '&.MuiTab-fullWidth .MuiTab-wrapper': {
              flexDirection: 'row',
              justifyContent: 'left'
            }
          }
        }
      },

      MuiAlert: {
        styleOverrides: {
          root: {
            fontSize: 14
          }
        }
      },

      MuiSelect: {
        styleOverrides: {}
      },
      MuiTooltip: {
        // Sadly have to use !important to make sure these custom styles
        // are applied to tooltips inside the Ej2Grid.
        styleOverrides: {
          tooltip: {
            backgroundColor: 'rgba(0, 0, 0, .9) !important',
            width: '250px  !important',

            '& p': {
              fontSize: 12,
              fontWeight: 400,
              lineHeight: 1.4,
              margin: 0,
              whiteSpace: 'pre-wrap'
            }
          }
        }
      },
      MuiTable: {
        variants: [
          {
            props: { padding: 'none' },
            style: {
              '& .MuiTableCell-root': {
                padding: '2.5px'
              }
            }
          }
        ]
      },

      MuiTableCell: {
        styleOverrides: {
          body: {
            border: 'none',
            letterSpacing: 0
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          spacer: {
            flex: 0
          },
          toolbar: {
            paddingLeft: 7,
            paddingRight: 7
          }
        }
      },
      MuiRating: {
        styleOverrides: {
          iconFilled: {
            color: RM_PRIMARY_COLOR
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow:
              '0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)',
            overflow: 'visible'
          }
        }
      },
      MuiCardHeader: {
        styleOverrides: {
          title: {
            fontSize: 16,
            margin: 0
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: '#fff',
            padding: 15,
            width: '100%',

            [defaultTheme.breakpoints.up('sm')]: {
              padding: 30,
              maxWidth: '100%',
              width: 1100
            }
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: '16px 24px'
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '8px 24px'
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '16px 24px'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            clear: 'both'
          }
        }
      }
    }
  });
};
